@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "~@ng-select/ng-select/themes/default.theme.css";


:root {
  --abaut-bright-red-color:         #f75552;
  --abaut-red-color:         #B11C19;
  --abaut-dark-grey-color:   #5B5B5B;
  --abaut-white-color:       #FFFFFF;
  --abaut-black-color:       #000000;
  --abaut-black-transparency-hover-color: rgba(0, 0, 0, 1.0);
  --abaut-black-transparency-color: rgba(0, 0, 0, 0.7);
  --abaut-light-grey-color:  #AAAAAA;
  --abaut-disable-grey-color: #7B7B7B;
  --abaut-dark-blue-color:         #0072C6;
  --abaut-light-blue-color:        #0070C0;
  --abaut-green-color:       #00B050;
  --abaut-purple-color:      #7030A0;



  --abaut-dark-grey-old-color:   #404040;

  --abaut-border-inner-radius: 3px;
  --abaut-border-outer-radius: 5px;


}


html, body {
  width: 100%;
  height: 100%;
  overflow-y:hidden;
}

body {
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
  //overscroll restrict giving the scroll controls to the browser
  overscroll-behavior: contain;
}

 // This will be the default color of the day without any images.
.mat-calendar-table {
  td:first-child > .mat-calendar-body-cell-content {
    color: var(--abaut-dark-grey-color) !important;
  }
  td:nth-child(7) > .mat-calendar-body-cell-content {
    color: var(--abaut-dark-grey-color) !important;
  }
}

//Currently this is used for the days with images
.calendar-custom-date-class {
  // background: red;
  // border-radius: 140%;
  // border-color: red;
  // border:1px solid red;
  font-weight: bold;
  font-size: 0.9rem;
}


//Currently this is used for the days without any images
.calendar-custom-date-class_two {
  font-weight: normal;
  color: var(--abaut-grey-color);

  // This will prevent any clicking
  pointer-events: none;

}




// This is for the currently selcted date, for some reason the font color needs to be set again.
.mat-calendar-body-selected{
  background-color: var(--abaut-dark-blue-color);
  font-weight: bold;
  font-size: 0.9rem;
  color: var(--abaut-black-color);
}

// .example-custom-date-class1 {
//   // background: yellow;
//   border-radius: 140%;
//   border-color: red;
//   border:1px solid red;
// }

.red-progress-color .mat-progress-bar-fill::after {
  background-color:  var(--abaut-red-color);
}


// .mat-progress-spinner circle, .mat-spinner circle {
//   stroke: var(--abaut-red-color);
//   }


/* Info hotspot */

.info-hotspot {
  width: 250px !important;
  height: 250px !important;
  line-height: 1.2em;
  opacity: 0.9;
  -webkit-transition: opacity 0.2s 0.2s;
  transition: opacity 0.2s 0.2s;
}

.no-touch .info-hotspot:hover {
  opacity: 1;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.info-hotspot.visible {
  opacity: 1;
}

.info-hotspot .info-hotspot-header {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  background-color: red;
  cursor: pointer;
  -webkit-transition: width 0.3s ease-in-out 0.5s,
                      border-radius 0.3s ease-in-out 0.5s;
  transition: width 0.3s ease-in-out 0.5s,
              border-radius 0.3s ease-in-out 0.5s;
}

.mobile .info-hotspot .info-hotspot-header {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.desktop.no-touch .info-hotspot .info-hotspot-header:hover {
  width: 260px;
  border-radius: var(--abaut-border-outer-radius);;
  -webkit-transition: width 0.3s ease-in-out,
                      border-radius 0.3s ease-in-out;
  transition: width 0.3s ease-in-out,
              border-radius 0.3s ease-in-out;
}

.desktop .info-hotspot.visible .info-hotspot-header,
.desktop.no-touch .info-hotspot.visible .info-hotspot-header:hover {
  width: 260px;
  border-radius: var(--abaut-border-outer-radius);;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-transition: width 0.3s ease-in-out,
                      border-radius 0.3s ease-in-out;
  transition: width 0.3s ease-in-out,
              border-radius 0.3s ease-in-out;
}

.info-hotspot .info-hotspot-icon-wrapper {
  width: 230%;
  height: 230%;
}

.mobile .info-hotspot .info-hotspot-icon-wrapper {
  width: 50px;
  height: 50px;
}

.info-hotspot .info-hotspot-icon {
  width: 35%;
  height: 35%;
  margin: 5%;
}

.info-hotspot .info-hotspot-title-wrapper {
  position: absolute;
  left: 40px;
  top: 0;
  width: 0;
  height: 40px;
  padding: 0;
  overflow: hidden;
  -webkit-transition: width 0s 0.4s,
                      padding 0s 0.4s;
  transition: width 0s 0.4s,
              padding 0s 0.4s;
}

.desktop .info-hotspot.visible .info-hotspot-title-wrapper,
.desktop.no-touch .info-hotspot .info-hotspot-header:hover .info-hotspot-title-wrapper {
  width: 220px;
  padding: 0 5px;
  -webkit-transition: width 0s 0.4s,
                      padding 0s 0.4s;
  transition: width 0s 0.4s,
              padding 0s 0.4s;
}

.info-hotspot .info-hotspot-title-wrapper:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.info-hotspot .info-hotspot-title {
  display: inline-block;
  vertical-align: middle;

  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.info-hotspot .info-hotspot-close-wrapper {
  position: absolute;
  left: 260px;
  top: 0;
  height: 40px;
  width: 40px;
  border-top-right-radius: 5px;
  background-color: rgb(78,88,104);
  visibility: hidden;
  -ms-transform: perspective(200px) rotateY(90deg);
  -webkit-transform: perspective(200px) rotateY(90deg);
  transform: perspective(200px) rotateY(90deg);
  -ms-transform-origin: 0 50% 0;
  -webkit-transform-origin: 0 50% 0;
  transform-origin: 0 50% 0;
  -webkit-transition: -ms-transform 0.3s 0.3s,
                      -webkit-transform 0.3s 0.3s,
                      transform 0.3s 0.3s,
                      visibility 0s 0.6s;
  transition: -ms-transform 0.3s 0.3s,
              -webkit-transform 0.3s 0.3s,
              transform 0.3s 0.3s,
              visibility 0s 0.6s;
}

.desktop .info-hotspot.visible .info-hotspot-close-wrapper {
  visibility: visible;
  -ms-transform: perspective(200px) rotateY(0deg);
  -webkit-transform: perspective(200px) rotateY(0deg);
  transform: perspective(200px) rotateY(0deg);
  -webkit-transition: -ms-transform 0.3s,
                      -webkit-transform 0.3s,
                      transform 0.3s,
                      visibility 0s 0s;
  transition: -ms-transform 0.3s,
              -webkit-transform 0.3s,
              transform 0.3s,
              visibility 0s 0s;
}

.info-hotspot .info-hotspot-close-icon {
  width: 70%;
  height: 70%;
  margin: 15%;
}

.info-hotspot .info-hotspot-text {
  position: absolute;
  width: 300px;
  height: auto;
  max-height: 200px;
  top: 40px;
  left: 0;
  padding: 10px;
  background-color: rgb(58,68,84);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow-y: auto;
  visibility: hidden;
  /* rotate(90deg) causes transition flicker on Firefox 58 */
  -ms-transform: perspective(200px) rotateX(-89.999deg);
  -webkit-transform: perspective(200px) rotateX(-89.999deg);
  transform: perspective(200px) rotateX(-89.999deg);
  -ms-transform-origin: 50% 0 0;
  -webkit-transform-origin: 50% 0 0;
  transform-origin: 50% 0 0;
  -webkit-transition: -ms-transform 0.3s,
                      -webkit-transform 0.3s,
                      transform 0.3s,
                      visibility 0s 0.3s;
  transition: -ms-transform 0.3s,
              -webkit-transform 0.3s,
              transform 0.3s,
              visibility 0s 0.3s;

  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.desktop .info-hotspot.visible .info-hotspot-text {
  visibility: visible;
  -ms-transform: perspective(200px) rotateX(0deg);
  -webkit-transform: perspective(200px) rotateX(0deg);
  transform: perspective(200px) rotateX(0deg);
  -webkit-transition: -ms-transform 0.3s 0.3s,
                      -webkit-transform 0.3s 0.3s,
                      transform 0.3s 0.3s,
                      visibility 0s 0s;
  transition: -ms-transform 0.3s 0.3s,
              -webkit-transform 0.3s 0.3s,
              transform 0.3s 0.3s,
              visibility 0s 0s;
}

/* Info hotspot modal */

.desktop .info-hotspot-modal {
  display: none;
}

.info-hotspot-modal {
  font-weight: bold;
  color: white;
  top: 30%;
  left: 30%;
  position: absolute;
  width: 400px !important;
  height: 400px !important;

  // width: 100%;
  // height: 100%;
  overflow: hidden;
  z-index: 11000 !important;
  // background-color: rgba(0,0,0,.5);
  line-height: 1.2em;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.2s ease-in-out 0.5s,
                      visibility 0s 0.7s;
  transition: opacity 0.2s ease-in-out 0.5s,
              visibility 0s 0.7s;
}

.info-hotspot-modal.visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.2s ease-in-out,
                      visibility 0s 0s;
  transition: opacity 0.2s ease-in-out,
              visibility 0s 0s;
}

.info-hotspot-modal .info-hotspot-header {
  position: absolute;
  top: 60px;
  left: 10px;
  right: 10px;
  width: auto;
  height: 50px;
  background-color: rgb(103,115,131);
  background-color: rgba(103,115,131,0.8);
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out 0.2s;
  transition: opacity 0.3s ease-in-out 0.2s;
}

.info-hotspot-modal.visible .info-hotspot-header {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out 0.2s;
  transition: opacity 0.3s ease-in-out 0.2s;
}

.info-hotspot-modal .info-hotspot-icon-wrapper {
  width: 50px;
  height: 50px;
}

.info-hotspot-modal .info-hotspot-icon {
  width: 90%;
  height: 90%;
  margin: 5%;
}

.info-hotspot-modal .info-hotspot-title-wrapper {
  position: absolute;
  top: 0;
  left: 50px;
  right: 50px;
  width: auto;
  height: 50px;
  padding: 0 10px;
}

.info-hotspot-modal .info-hotspot-title-wrapper:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.info-hotspot-modal .info-hotspot-title {
  display: inline-block;
  vertical-align: middle;

  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.info-hotspot-modal .info-hotspot-close-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-color: rgb(78,88,104);
  background-color: rgba(78,88,104,0.8);
  cursor: pointer;
}

.info-hotspot-modal .info-hotspot-close-icon {
  width: 70%;
  height: 70%;
  margin: 15%;
}

.info-hotspot-modal .info-hotspot-text {
  position: absolute;
  top: 110px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  padding: 10px;
  background-color: rgb(58,68,84);
  background-color: rgba(58,68,84,0.8);
  overflow-y: auto;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;

  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.info-hotspot-modal.visible .info-hotspot-text {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out 0.4s;
  transition: opacity 0.3s ease-in-out 0.4s;
}




.desktop .info-hotspot.visible .info-hotspot-header,
.desktop.no-touch .info-hotspot.visible .info-hotspot-header:hover {
  width: 260px;
  border-radius: var(--abaut-border-outer-radius);;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-transition: width 0.3s ease-in-out,
                      border-radius 0.3s ease-in-out;
  transition: width 0.3s ease-in-out,
              border-radius 0.3s ease-in-out;
}

.progress-item {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 1;
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  z-index: 0;
}

.red-progress
{

  position: fixed !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

}
.red-progress-mobile
{
  position: fixed !important;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
}
.mat-progress-spinner circle, .mat-spinner circle {
  stroke:  var(--abaut-red-color);
}

.spinner_overlay {

  display: grid;
  background-color: #fff;
  opacity: 0.5;
  z-index: 1000;
}

footer {
	/* z-index: 15; */
	z-index: 2000;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: #333;
	height: 40px;
	box-shadow: 0 0px 10px 10px rgba(0, 0, 0, 0.2);
}

footer p{
	text-align: right;
	color: #999;
	padding: 10px 20px 0px;
	margin: 0;
	font-size: 12px;
}

//Custom Styling for Select in Top Left Toolbar
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option {
  background: #2B2B2B;
  padding: 15px;
  font-size: 20px;
  color: white;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #FF6161;
  background-color: #2B2B2B;
}

.ng-select.ng-select-single .ng-select-container {
  height: 40px;
  background-color: #2B2B2B;
  border-radius: 10px;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #FFF transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
  border-color: transparent transparent #FFF;
  border-width: 0 5px 5px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-size: 20px;
}

// Tooltip Fontsize change
.mat-tooltip {
  font-size: 15px !important;
}
